import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { PLSchoolYearsService } from '@common/services';
import { PLHttpService } from '@root/src/lib-components';

@Component({
  selector: 'pl-assignment-manager-details-modal',
  templateUrl: './pl-assignment-manager-details-modal.component.html',
  styleUrls: ['./pl-assignment-manager-details-modal.component.less'],
})
export class PlAssignmentManagerDetailsModalComponent implements OnInit {
  @Input() salesforceUrl: string;
  @Input() salesforceId: string;
  @Input() closeDetailsOverlay: () => void;
  @Input() serviceModel: string;
  @Input() opportunityName: string;
  @Input() demand: string;
  @Input() schoolYear: string;

  serviceLine: string;
  loading: boolean = true;
  results: any = {};

  constructor(
    private plHttp: PLHttpService,
    private plSchoolYearsService: PLSchoolYearsService,
  ) {}

  ngOnInit(): void {
    const schoolYearCode = this.plSchoolYearsService.getYearForUUID(
      this.schoolYear,
    ).code;

    forkJoin([
      this.plHttp.get('assignmentProposals', {
        demand: this.demand,
        limit: 10,
      }),
      this.plHttp.get('opportunitys', {
        account_id: this.salesforceId,
        start_date: schoolYearCode,
      }),
    ]).subscribe(([assignmentProposals, opportunities]: any[]) => {
      const assignmentProposalResults =
        assignmentProposals.results?.pop() || {};
      this.serviceLine = assignmentProposalResults.service_lines?.[0];
      this.results = opportunities.results ? opportunities.results.pop() : {};
      this.loading = false;
    });
  }
}
