import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import * as Sentry from '@sentry/angular-ivy';
import { ApolloModule } from 'apollo-angular';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgProgressModule } from 'ngx-progressbar';
import { ToastrModule } from 'ngx-toastr';
import { RootStoreModule } from '@app/store/root-store.module';
import { CanDeactivateGuard } from '@common/can-deactivate-guard.service';
import { FiltersModule } from '@common/filters';
import { PLCommonModule } from '@common/index';
import {
  PLGetProviderService,
  PLLocationsService,
  PLSchoolYearsService,
  PLStatusDisplayService,
  PLClientsTableService,
  MAX_QUERY_LIMIT,
  PLPendoService,
} from '@common/services';
import { PlLocationMatchingComponent } from '@modules/add-referrals/pl-location-matching/pl-location-matching.component';
import {
  HeapLogger,
  PLUIStateModule,
  // Components
  PLAppNavModule,
  PLTopBarModule,
  PLAssumeLoginModule,
  PLButtonModule,
  PLBrowserModule,
  PLConfigModule,
  PLE2ETestModule,
  PLDotLoaderModule,
  PLGraphQLModule,
  PLInputModule,
  PLIconModule,
  PLLinkModule,
  PipeModule,
  PLModalModule,
  PLStepsModule,
  PLTableModule,
  PLTableFrameworkModule,
  PLTabsModule,
  PLToastModule,
  PLDialogModule,
  // Services
  PLAssumeLoginService,
  PLAbstractUserIDService,
  PLAbstractRealUserIDService,
  PLFormatterService,
  PLHttpModule,
  PLLodashService,
  PLMayService,
  PLTimezoneService,
  PLTransformGraphQLService,
  PLStylesService,
  PLWindowService,
  PLApiAbstractService,
  PLApiAccountDocumentTypesService,
  PLApiAccountUploadDocumentsService,
  PLApiAreasOfConcernService,
  PLApiAssessmentsService,
  PLApiBillingCodesService,
  PLApiClientsService,
  PLApiContactTypesService,
  PLApiDocumentTypesService,
  PLApiEthnicitiesService,
  PLApiFileAmazonService,
  PLApiLanguagesService,
  PLApiLocationsService,
  PLApiNotesSchemasService,
  PLApiProvidersService,
  PLApiProviderTypesService,
  PLApiRacesService,
  PLApiServiceDocumentsService,
  PLApiServiceTypesService,
  PLApiServicesService,
  PLApiUsStatesService,
  PLApiClientServicesService,
  PLApiServiceOptsService,
  PLApiServiceSaveService,
  PLApiServiceUploadDocumentsService,
  PLGQLClientsService,
  PLGQLClientServiceService,
  PLGQLLanguagesService,
  PLGQLLocationsService,
  PLGQLProvidersService,
  PLGQLProviderTypesService,
  PLGQLReferralsService,
  PLGQLServicesService,
  PLGQLQueriesService,
  PLGQLStringsService,
  PLClosablePageHeaderModule,
  PLVaryDisplayModule,
  PLHeaderModule,
} from '@root/index';
import { AppConfigService } from './app-config.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomMatIconsService } from './common/material/custom-mat-icons.service';
import { PLAddReferralsTableComponent } from './modules/add-referrals/common/pl-add-referrals-table.component';
import { PLAddReferralsGraphQLService } from './modules/add-referrals/pl-add-referrals-graphql.service';
import { PLAddReferralsLocationYearService } from './modules/add-referrals/pl-add-referrals-location-year.service';
import { PLAddReferralsNavigationService } from './modules/add-referrals/pl-add-referrals-navigation.service';
import { PLAddReferralsDataTableService } from './modules/add-referrals/pl-add-referrals-table-data.service';
import { PLAddReferralsComponent } from './modules/add-referrals/pl-add-referrals.component';
import { PLClientReferralDataModelService } from './modules/add-referrals/pl-client-referral-data-model.service';
import { PLErrorMessageComponent } from './modules/add-referrals/pl-error-message.component';
import { PLLocationBannerComponent } from './modules/add-referrals/pl-location-banner.component';
import { PLProviderMatchingComponent } from './modules/add-referrals/pl-provider-matching/pl-provider-matching.component';
import { PLReferralDuplicateComponent } from './modules/add-referrals/pl-referral-duplicate/pl-referral-duplicate.component';
import { PLNameChangeTableComponent } from './modules/add-referrals/pl-referrals-confirmation/pl-name-change-table.component';
import { PLReferralsConfirmationComponent } from './modules/add-referrals/pl-referrals-confirmation/pl-referrals-confirmation.component';
import { PLAddReferralsConfirmationService } from './modules/add-referrals/pl-referrals-confirmation/pl-referrals-confirmation.service';
import { PLSelectLocationComponent } from './modules/add-referrals/pl-select-location/pl-select-location.component';
import { PLFileImportService } from './modules/add-referrals/pl-upload-referrals/pl-file-import.service';
import { PLUploadReferralsComponent } from './modules/add-referrals/pl-upload-referrals/pl-upload-referrals.component';
import { PLAssignmentManagerModule } from './modules/assignment-manager';
import { PLDemandNotesModule } from './modules/assignment-manager/pl-demand-notes';
import { PLAssignmentPreferencesModule } from './modules/assignment-preferences';
import { PLCamDashboardModule } from './modules/cam-dashboard';
import { PLClientMergeModule } from './modules/client-merge';
import { PLClientReferralSaveModule } from './modules/client-referral-save';
import { PLClientReferralSaveComponent } from './modules/client-referral-save/pl-client-referral-save/pl-client-referral-save.component';
import { PLClientReferralSaveClientComponent } from './modules/client-referral-save/pl-client-referral-save-client/pl-client-referral-save-client.component';
import { PLClientReferralSaveReferralComponent } from './modules/client-referral-save/pl-client-referral-save-referral/pl-client-referral-save-referral.component';
import { PLClientReferralSaveService } from './modules/client-referral-save/pl-client-referral-save.service';
import { PLClientReferralsModule } from './modules/client-referrals';
import { PLClientReferralMatchComponent } from './modules/client-referrals/pl-client-referral-match/pl-client-referral-match.component';
import { PLClientContactEnableLoginComponent } from './modules/clients/pl-client-contact-enable-login/pl-client-contact-enable-login.component';
import { PLClientContactEnableSMSComponent } from './modules/clients/pl-client-contact-enable-sms/pl-client-contact-enable-sms.component';
import { PLClientReferralDeclineComponent } from './modules/clients/pl-client-direct-referral/pl-client-referral-decline/pl-client-referral-decline.component';
import { PLClientReferralDeleteComponent } from './modules/clients/pl-client-direct-referral/pl-client-referral-delete/pl-client-referral-delete.component';
import { PLClientIepGoalsAuthGuardService } from './modules/clients/pl-client-iep-goals/pl-client-iep-goals-authguard.service';
import { PLClientIEPGoalsService } from './modules/clients/pl-client-iep-goals/pl-client-iep-goals.service';
import { PLClientDeleteComponent } from './modules/clients/pl-client-profile-header/pl-client-delete/pl-client-delete.component';
import { PLClientServiceStatusEditComponent } from './modules/clients/pl-client-service-status-edit/pl-client-service-status-edit.component';
import { PLCustomerDashboardModule } from './modules/customer-dashboard';
import { PLCustomerDashboardService } from './modules/customer-dashboard/pl-customer-dashboard.service';
import { PLStudentStatusHelpComponent } from './modules/customer-dashboard/pl-student-status-help/pl-student-status-help.component';
import { PLDashboardModule } from './modules/dashboard';
import { PLMeetingsListComponent } from './modules/dashboard/pl-meetings-list/pl-meetings-list.component';
import { ConfigComponent } from './modules/dev/config/config.component';
import { plIconsDemoComponent } from './modules/dev/pl-icons/pl-icons-demo.component';
import { PLDownloadsModule } from './modules/downloads';
import { PLHomeModule } from './modules/home';
import { PLISADashboardComponent } from './modules/isa/pl-isa-dashboard/pl-isa-dashboard.component';
import { PLISAHandlingComponent } from './modules/isa/pl-isa-handling/pl-isa-handling.component';
import { PLISATableComponent } from './modules/isa/pl-isa-table/pl-isa-table.component';
import { PLLocationClientsConfirmComponent } from './modules/locations/pl-location-clients-confirm/pl-location-clients-confirm.component';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { PLPermissionsModule } from './modules/permissions';
import { PLLiveagentPagesModule } from './modules/pl-liveagent-pages';
import { PLThirdPartyPagesModule } from './modules/pl-third-party-pages';
import { PLProviderPreagreementComponent } from './modules/provider-onboarding/pl-provider-preagreement/pl-provider-preagreement.component';
import { PLProviderPreagreementW2Component } from './modules/provider-onboarding/pl-provider-preagreement-w2/pl-provider-preagreement-w2.component';
import { PLProviderComponent } from './modules/providers/pl-provider/pl-provider.component';
import { PLProviderAreasOfSpecialtyComponent } from './modules/providers/pl-provider-account-settings/pl-provider-areas-of-specialty.component';
import { PLProviderAssignmentTypeComponent } from './modules/providers/pl-provider-account-settings/pl-provider-assignment-type.component';
import { PLProviderLanguagesComponent } from './modules/providers/pl-provider-account-settings/pl-provider-languages.component';
import { PLProviderNotificationPreferencesComponent } from './modules/providers/pl-provider-account-settings/pl-provider-notification-preferences.component';
import { PLProviderAssumeLoginButtonComponent } from './modules/providers/pl-provider-assume-login-button/pl-provider-assume-login-button.component';
import { PLProviderAvailabilityComponent } from './modules/providers/pl-provider-availability/pl-provider-availability.component';
import { PLProviderCaseloadComponent } from './modules/providers/pl-provider-caseload/pl-provider-caseload.component';
import { PLProviderDetailsModule } from './modules/providers/pl-provider-details/pl-provider-details.module';
import { PLProviderLocationsComponent } from './modules/providers/pl-provider-locations/pl-provider-locations.component';
import { PLProviderPayRatesComponent } from './modules/providers/pl-provider-pay-rates/pl-provider-pay-rates.component';
import { PLProviderProfileAssignmentsComponent } from './modules/providers/pl-provider-profile-assignments/pl-provider-profile-assignments.component';
import { PLProviderProfileHeaderComponent } from './modules/providers/pl-provider-profile-header/pl-provider-profile-header.component';
import { PlProviderQualificationsComponent } from './modules/providers/pl-provider-qualifications/pl-provider-qualifications.component';
import { PLProviderService } from './modules/providers/pl-provider.service';
import { PLProvidersListComponent } from './modules/providers/pl-providers-list/pl-providers-list.component';
import { PLScheduleAvailabilityComponentsModule } from './modules/schedule';
import { PLSearchModule } from './modules/search/search.module';
import { PLServiceSaveComponent } from './modules/service-save/pl-service-save/pl-service-save.component';
import { PLServiceSaveAssignComponent } from './modules/service-save/pl-service-save-assign/pl-service-save-assign.component';
import { PLServiceSaveClientDetailsComponent } from './modules/service-save/pl-service-save-client-details/pl-service-save-client-details.component';
import { PLServiceSaveDocumentationComponent } from './modules/service-save/pl-service-save-documentation/pl-service-save-documentation.component';
import { PLServiceSaveIdentifyComponent } from './modules/service-save/pl-service-save-identify/pl-service-save-identify.component';
import { PLServiceSaveServiceDetailsComponent } from './modules/service-save/pl-service-save-service-details/pl-service-save-service-details.component';
import { PLServiceSaveService } from './modules/service-save/pl-service-save.service';
import { PLUserModule } from './modules/user';
import { CurrentUserRealUserIDService } from './modules/user/current-user-real-user-id.service';
import { CurrentUserUserIDService } from './modules/user/current-user-user-id.service';
import { CurrentUserService } from './modules/user/current-user.service';
import { InactivityLogoutService } from './modules/user/inactivity-logout.service';
import { plLogoutComponent } from './modules/user/pl-logout/pl-logout.component';
import { UserAuthGuardService } from './modules/user/user-auth-guard.service';
import { UserCanAccessAssignmentsAuthGuardService } from './modules/user/user-can-access-assignments-auth-guard.service';
import { UserCanAccessClientReferralManagerAuthGuardService } from './modules/user/user-can-access-client-referral-manager-auth-guard.service';
import { UserCanAccessPLProviderLandingAuthGuardService } from './modules/user/user-can-access-pl-provider-landing-auth-guard.service';
import { UserCanAccessSSPProviderLandingAuthGuardService } from './modules/user/user-can-access-ssp-provider-landing-auth-guard.service';

import { PLUsersModule } from './modules/users';
import { SvgInlineNgPluginService } from '../build/svg-inline-ng-plugin.service';

@NgModule({
  imports: [
    ApolloModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // NgRx
    RootStoreModule,
    // PL Edu Client Modules => Should go before App Routing so Route work
    PLSearchModule,
    AppRoutingModule,
    PLCommonModule,
    FiltersModule,
    PLClientMergeModule,
    PLAssignmentManagerModule,
    PipeModule,
    // Components
    PLAppNavModule,
    PLTopBarModule,
    PLAssumeLoginModule,
    PLButtonModule,
    PLBrowserModule,
    PLConfigModule,
    PLE2ETestModule,
    PLDotLoaderModule,
    PLGraphQLModule,
    PLIconModule,
    PLInputModule,
    PLLinkModule,
    PLModalModule,
    PLStepsModule,
    PLTableModule,
    PLTableFrameworkModule,
    PLTabsModule,
    PLToastModule,
    PLDialogModule,
    PLClosablePageHeaderModule,
    PLVaryDisplayModule,
    PLPermissionsModule,
    PLHeaderModule,
    PLProviderDetailsModule,
    // Services
    PLHttpModule,
    PLHomeModule,
    PLAssignmentPreferencesModule,
    PLClientReferralsModule,
    PLCamDashboardModule,
    PLCustomerDashboardModule,
    PLClientReferralSaveModule,
    PLDownloadsModule,
    PLUsersModule,
    PLUIStateModule,
    PLLiveagentPagesModule,
    PLThirdPartyPagesModule,
    // Need it for provider module, once is remove we can import in its own module
    PLScheduleAvailabilityComponentsModule,
    PLUserModule,
    NgProgressModule,
    NgxJsonViewerModule,
    ToastrModule.forRoot(),
    ScrollingModule,
    PLDashboardModule,
    MatTooltipModule,
    MatSelectModule,
    MatButtonModule,
    MatSnackBarModule,
    PLDemandNotesModule,
    OverlayModule,
    PortalModule,
    MatIconModule,
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    plLogoutComponent,
    PLMeetingsListComponent,
    PLAddReferralsComponent,
    PLLocationBannerComponent,
    PLLocationClientsConfirmComponent,
    PLErrorMessageComponent,
    PLSelectLocationComponent,
    PLUploadReferralsComponent,
    PLProviderMatchingComponent,
    PlLocationMatchingComponent,
    PLReferralsConfirmationComponent,
    PLReferralDuplicateComponent,
    PLAddReferralsTableComponent,
    PLNameChangeTableComponent,
    PLClientReferralDeleteComponent,
    PLClientReferralMatchComponent,
    PLClientReferralDeclineComponent,
    PLClientServiceStatusEditComponent,
    PLClientDeleteComponent,
    PLClientReferralSaveComponent,
    PLClientReferralSaveClientComponent,
    PLClientReferralSaveReferralComponent,
    PLClientContactEnableSMSComponent,
    PLClientContactEnableLoginComponent,
    PLServiceSaveComponent,
    PLServiceSaveIdentifyComponent,
    PLServiceSaveDocumentationComponent,
    PLServiceSaveClientDetailsComponent,
    PLServiceSaveServiceDetailsComponent,
    PLServiceSaveAssignComponent,
    PLProvidersListComponent,
    PLProviderComponent,
    PLProviderProfileHeaderComponent,
    PLProviderAssumeLoginButtonComponent,
    PLProviderCaseloadComponent,
    PlProviderQualificationsComponent,
    PLProviderLocationsComponent,
    PLProviderAreasOfSpecialtyComponent,
    PLProviderLanguagesComponent,
    PLProviderNotificationPreferencesComponent,
    PLProviderAssignmentTypeComponent,
    PLProviderProfileAssignmentsComponent,
    PLProviderAvailabilityComponent,
    PLProviderPreagreementComponent,
    PLProviderPreagreementW2Component,
    PLProviderPayRatesComponent,
    PLStudentStatusHelpComponent,
    plIconsDemoComponent,
    ConfigComponent,
    PlProviderQualificationsComponent,
    PLISADashboardComponent,
    PLISATableComponent,
    PLISAHandlingComponent,
  ],
  providers: [
    // Services
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: function initializeCustomIcons(
        service: CustomMatIconsService,
      ) {
        return () => service.register();
      },
      multi: true,
      deps: [CustomMatIconsService, Sentry.TraceService],
    },
    PLAssumeLoginService,
    { provide: PLAbstractUserIDService, useClass: CurrentUserUserIDService },
    {
      provide: PLAbstractRealUserIDService,
      useClass: CurrentUserRealUserIDService,
    },
    ApolloModule,
    PLFormatterService,
    PLLodashService,
    PLMayService,
    PLTimezoneService,
    PLTransformGraphQLService,
    PLStylesService,
    PLWindowService,
    PLApiAbstractService,
    PLApiAccountDocumentTypesService,
    PLApiAccountUploadDocumentsService,
    PLApiAreasOfConcernService,
    PLApiAssessmentsService,
    PLApiBillingCodesService,
    PLApiClientsService,
    PLApiContactTypesService,
    PLApiDocumentTypesService,
    PLApiEthnicitiesService,
    PLApiFileAmazonService,
    PLApiLanguagesService,
    PLApiLocationsService,
    PLApiNotesSchemasService,
    PLApiProvidersService,
    PLApiProviderTypesService,
    PLApiRacesService,
    PLApiServiceDocumentsService,
    PLApiServiceTypesService,
    PLApiServicesService,
    PLApiUsStatesService,
    PLApiClientServicesService,
    PLApiServiceOptsService,
    PLApiServiceSaveService,
    PLApiServiceUploadDocumentsService,
    PLGQLClientsService,
    PLGQLClientServiceService,
    PLGQLLanguagesService,
    PLGQLLocationsService,
    PLGQLProvidersService,
    PLGQLProviderTypesService,
    PLGQLReferralsService,
    PLGQLServicesService,
    PLGQLQueriesService,
    PLGQLStringsService,
    PLClientReferralSaveService,
    PLProviderService,
    PLServiceSaveService,
    PLClientIEPGoalsService,
    PLClientIepGoalsAuthGuardService,
    PLFileImportService,
    PLAddReferralsNavigationService,
    PLAddReferralsConfirmationService,
    PLAddReferralsLocationYearService,
    PLAddReferralsDataTableService,
    PLAddReferralsGraphQLService,
    PLClientReferralDataModelService,
    PLGetProviderService,
    PLLocationsService,
    PLSchoolYearsService,
    PLStatusDisplayService,
    PLClientsTableService,
    PLCustomerDashboardService,
    AppConfigService,
    SvgInlineNgPluginService,
    CurrentUserService,
    UserAuthGuardService,
    UserCanAccessAssignmentsAuthGuardService,
    UserCanAccessPLProviderLandingAuthGuardService,
    UserCanAccessSSPProviderLandingAuthGuardService,
    UserCanAccessClientReferralManagerAuthGuardService,
    InactivityLogoutService,
    CanDeactivateGuard,
    HeapLogger,
    PLPendoService,
    { provide: MAX_QUERY_LIMIT, useValue: 1000 },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
